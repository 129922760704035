import jquery from 'jquery';
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import './scss/index.scss';
import '@popperjs/core';
import 'bootstrap';
import '@fancyapps/ui';

// Tailwind CSS Framework
//import './pcss/index.pcss';

import "slick-carousel";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

//#region footer back to top
$('.footer__back > a').on('click', function(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
});
//#endregion

//#region home slider
$('.slider').slick({
        prevArrow: '.slider__arrow--prev',
        nextArrow: '.slider__arrow--next',
    })
    //#endregion

$('.navbar-nav>li>a').on('click', function() {
    $('.navbar-collapse').collapse('hide');
});

//#region LoadMore Button

$(".gallery-item").slice(0, 15).show();
$("#loadMore").on("click", function(e) {
    e.preventDefault();
    $(".gallery-item:hidden").slice(0, 10).slideDown();
    if ($(".gallery-item:hidden").length == 0) {
        $("#loadMore").text("no more images").addClass("noContent");
    }
});

//#enregion


//#region bootstrap navbar + hamburger + body scroll lock
var navbarMain = document.getElementById('navbarMain')
navbarMain.addEventListener('show.bs.collapse', function() {
    $('.navbar-toggler .hamburger').addClass('is-active');
    let scrollableNav = document.querySelector('#navbarMain');
    disableBodyScroll(scrollableNav);
});
navbarMain.addEventListener('hidden.bs.collapse', function() {
    $('.navbar-toggler .hamburger').removeClass('is-active');
    let scrollableNav = document.querySelector('#navbarMain');
    enableBodyScroll(scrollableNav);
});
//#endregion

document.addEventListener("DOMContentLoaded", function() {
    window.addEventListener('scroll', function() {
        if (window.scrollY > 420) {
            document.getElementById('sticky-top').classList.add('fixed-top');
            document.getElementById('logo-standard').classList.add('d-none');
            document.getElementById('logo-sticky').classList.remove('d-none');
            document.getElementById('navbar').classList.add('dark-bg');
            document.getElementById('backtohead').classList.remove('d-none');
            document.getElementById('credits').classList.add('d-lg-none');
            // add padding top to show content behind navbar
            navbar_height = document.querySelector('.navbar').offsetHeight;
            document.body.style.paddingTop = navbar_height + 'px';
        } else {
            document.getElementById('sticky-top').classList.remove('fixed-top');
            document.getElementById('logo-sticky').classList.add('d-none');
            document.getElementById('logo-standard').classList.remove('d-none');
            document.getElementById('navbar').classList.remove('dark-bg');
            document.getElementById('backtohead').classList.add('d-none');
            document.getElementById('credits').classList.remove('d-lg-none');
            // remove padding top from body
            document.body.style.paddingTop = '0';
        }
    });
});